import { useMemo, useState } from "react";
import {
  Card,
  Checkbox,
  Datepicker,
  Modal,
  Spinner,
  Button,
} from "flowbite-react";

const AppointmentOption = ({
  title,
  value,
  description,
  selected,
  options,
  price,
  onChange,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const minDate = useMemo(() => {
    if (options && options.length > 0) {
      const parts = options[0].date.split("-");
      return new Date(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10)
      );
    }
    return new Date();
  }, [options]);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleSelected = () => {
    if (Array.isArray(options) && options.length > 0) {
      setModalVisible(false);
      onChange({ value, options, date: minDate });
    }
  };

  return (
    <>
      {description ? (
        <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <p
                className="text-base leading-relaxed text-gray-500 dark:text-gray-400"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSelected}>I agree to the above</Button>
            <Button color="gray" onClick={() => setModalVisible(false)}>
              Decline
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      <Card className="mb-4">
        <div className="flex items-center gap-4">
          <Checkbox
            onClick={description ? showModal : handleSelected}
            checked={selected}
            className={`h-6 w-6 ${
              (options || []).length > 0 ? "" : "cursor-not-allowed"
            }`}
          />
          <label className="flex-grow">
            <div>{title}</div>
          </label>
          <label className="w-20 text-right">{price}</label>
          <div className="text-center w-48">
            <p>First Availability</p>
            {!Array.isArray(options) ? (
              <Spinner className="h-8 w-8 fill-black" />
            ) : null}
            {Array.isArray(options) && options.length === 0 ? (
              <span>No available options</span>
            ) : null}

            {Array.isArray(options) && options.length > 0 ? (
              <Datepicker
                defaultDate={minDate}
                key={minDate.toString()}
                language="en-GB"
                disabled
              />
            ) : null}
          </div>
        </div>
      </Card>
    </>
  );
};

export default AppointmentOption;
