import { useState, useCallback, useEffect, useMemo } from "react";
import { States } from "../reducers/Booking";
import { useDebounce, usePrevious } from "@uidotdev/usehooks";
import { diff } from "deep-object-diff";
import AnalyticsService from "../services/analytics";

const DEBOUNCE_TIME_MSECS = 2000;

const TAGS = {
  customerDetailsConfirmed: (obj) => obj["existentCustomer"],
  preferredLocationName: (obj) => obj["dealer.description"],
  preferredLocationID: (obj) => obj["dealer.dealerId"],
  service: (obj) =>
    Object.keys(obj || {})
      .filter(
        (key) => key.startsWith("repairOperations.") && key.endsWith(".code")
      )
      .map((key) => key.substring(key.indexOf(".") + 1, key.lastIndexOf(".")))
      .map(
        (key) =>
          obj[`repairOperations.${key}.code`] +
          ":" +
          obj[`repairOperations.${key}.description`]
      )
      .join("\\n") || null,
  otherWorkNote: (obj) => obj["additionalRepairOperations"],
  appointmentName: (obj) => obj["availability.value"],
  appointmentDateTime: (obj) => {
    const date = obj["availability.date"];
    const time = obj["availability.time"];

    if (date && time) {
      return `${date.getFullYear()}-${date.getMonth() > 8 ? "" : "0"}${
        date.getMonth() + 1
      }-${date.getDate() > 9 ? "" : "0"}${date.getDate()}T${time}:00Z`;
    }

    return null;
  },
  hasActivePlanWithFeasa: (obj) => obj["feasaServicePlan"],
  provideServicePlanQuote: (obj) => obj["feasaQuote"],
};

const flatten = (obj) => {
  const result = {};
  for (const key of Object.keys(obj || {})) {
    if (typeof obj[key] === "object") {
      if (obj[key] instanceof Date) {
        result[key] = obj[key];
      } else {
        const nested = flatten(obj[key]);
        for (const nestedKey of Object.keys(nested)) {
          result[`${key}.${nestedKey}`] = nested[nestedKey];
        }
      }
    } else {
      result[key] = obj[key];
    }
  }
  return result;
};

const extractTags = (object) => {
  return Object.keys(TAGS).reduce((acc, key) => {
    const res = TAGS[key](object);
    return res == null ? acc : { ...acc, [key]: res };
  }, {});
};

const useAnalytics = (formState) => {
  const [session, setSession] = useState(null);
  const debouncedSession = useDebounce(session, DEBOUNCE_TIME_MSECS);

  const prevFormState = usePrevious(formState);

  const createSession = useCallback((payload) => {
    AnalyticsService.createSession(payload)
      .then(({ id }) => setSession({ id }))
      .catch(() => setSession(null));
  }, []);

  const updateSession = useCallback((id, payload) => {
    AnalyticsService.updateSession(id, payload)
      .then((_res) => setSession({ id }))
      .catch(() => {});
  }, []);

  const closeSession = useCallback((id, payload) => {
    AnalyticsService.closeSession(id, payload).then(() => setSession(null));
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (session == null || session.id == null) {
        return;
      }

      AnalyticsService.triggerBeacon(session.id, {
        ...(session?.payload || {}),
        error: {
          field: "",
          type: "User",
          message: "Window closed",
        },
        failed: true,
      });
    };
  }, [closeSession, session]);

  const isExistentCustomer = useMemo(() => {
    const { existentCustomer } = formState;
    return existentCustomer || false;
  }, [formState]);

  useEffect(() => {
    if (formState == null || prevFormState == null) {
      return;
    }

    const { state } = formState || {};
    const { state: prevState } = prevFormState || {};

    const payload = {
      step: null,
      fields: null,
      tags: null,
      error: null,
    };

    if (!isExistentCustomer) {
      return;
    }

    if (state !== prevState) {
      if (prevState === States.INITIAL) {
        const { customer, vehicle, opportunity } = formState;
        const { sfCustomerId } = customer || {};
        const { sfVehicleId, make, model } = vehicle || {};
        const { sfOpportunityId } = opportunity || {};

        if (sfCustomerId && sfVehicleId) {
          createSession({
            customer: sfCustomerId,
            vehicle: sfVehicleId,
            opportunity: sfOpportunityId,
            make,
            model,
          });
          return;
        }
      } else if (state === States.COMPLETE) {
        return;
      }

      payload.step = state;
    }

    const delta = flatten(diff(prevFormState, formState));
    payload.fields = Object.keys(delta).filter(
      (f) =>
        f !== "state" &&
        !f.startsWith("availability.options.") &&
        !f.startsWith("repairOperations.")
    );

    payload.tags = extractTags(delta);

    setSession((prev) => {
      const { id, payload: prevPayload } = prev || {};
      const { step, fields, tags, error } = prevPayload || {};

      setSession({
        id,
        payload: {
          step: payload.step || step,
          fields: [...(fields || []), ...(payload.fields || [])],
          tags: { ...(tags || []), ...(payload.tags || []) },
          error: error,
        },
      });
    });
  }, [isExistentCustomer, formState, prevFormState, createSession]);

  useEffect(() => {
    if (
      session == null ||
      session.id == null ||
      formState.state !== States.COMPLETE
    ) {
      return;
    }

    const delta = flatten(formState);

    closeSession(session.id, {
      ...session.payload,
      tags: extractTags(delta),
      failed: false,
      step: "Complete",
    });
  }, [closeSession, formState, session]);

  useEffect(() => {
    const { id, payload } = debouncedSession || {};
    if (id == null || payload == null) {
      return;
    }

    updateSession(id, payload);
  }, [debouncedSession, updateSession]);

  const onError = useCallback(
    (error) => {
      if (isExistentCustomer && session?.id) {
        const { id, payload: sessionPayload } = session || {};
        const { step, fields, tags } = sessionPayload || {};

        setSession({
          id,
          payload: {
            step,
            fields,
            tags,
            error,
          },
        });
      }
    },
    [isExistentCustomer, session]
  );

  const onFieldChange = useCallback(
    (name, value) => {
      if (isExistentCustomer && session?.id) {
        const { id, payload: sessionPayload } = session || {};
        const { step, fields, tags, error } = sessionPayload || {};

        const payload = {
          fields: [name],
          tags: extractTags({ [name]: value }),
        };

        setSession({
          id,
          payload: {
            step,
            fields: [...(fields || []), ...(payload.fields || [])],
            tags: { ...(tags || []), ...(payload.tags || []) },
            error,
          },
        });
      }
    },
    [isExistentCustomer, session]
  );

  const res = useMemo(
    () => ({
      onError,
      onFieldChange,
    }),
    [onError, onFieldChange]
  );

  return res;
};

export default useAnalytics;
